@import "stylesheets/variables.scss";

.chatButton {
    position: fixed; // Position button on the screen
    bottom: 20px; // Distance from the bottom
    right: 20px; // Distance from the right
    width: 60px; // Button width
    height: 60px; // Button height
    background-color: $button-color; // Primary button color
    color: white; // Text/icon color
    border: none; // Remove borders
    border-radius: 50%; // Circular button
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); // Add shadow for depth
    font-size: 24px; // Adjust icon size
    cursor: pointer; // Pointer on hover
    display: flex; // Center content in the button
    justify-content: center; // Horizontal center alignment
    align-items: center; // Vertical center alignment
    transition: transform 0.3s, background-color 0.3s; // Smooth transitions for hover effects

    &:hover {
      background-color: $layout-color; // Darken button background on hover
      transform: scale(1.1); // Slightly enlarge on hover
    }
  
    &:active {
      transform: scale(0.95); // Slightly shrink on click
    }
  
    .img {
      width: 55px; 
      height: 55px;
    }
  }
.chatWindow {
    position: fixed; // Fixed position on the screen
    bottom: 80px; // Above the chatbot button
    right: 20px; // Slightly inward from the right
    width: 90%; /* Takes 90% of the parent container */
    max-width: 500px; /* Limits the width on large screens */
    min-width: 320px; /* Ensures it's not too small */
    max-height: 600px; // Limit chat window height
    display: flex;
    flex-direction: column; // Arrange items vertically
    background-color: $body-color; // Background color
    border-radius: 10px; // Rounded corners
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); // Add shadow
    overflow: hidden; // Keep everything inside the box
    z-index: 1000; // Make it appear above other elements
  
    .chatHeader {
      padding: 10px 15px;
      background-color: $button-color; // Header background
      color: #ffffff; // Header text color
      font-size: 16px; // Header text size
      font-weight: bold; // Ensure text is bold
      display: flex;
      justify-content: space-between; // Space between title and close button
      align-items: center;
  
      button {
        background: none; // Remove default button styles
        border: none;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .suggestionsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      margin: 10px
    }
    .suggestionButton {
      background-color: $button-color;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 6px 12px;
      cursor: pointer;
      font-size: 12px;
      flex: 1 1 45%;
      text-align: center;
      transition: $button-color 0.3s ease, transform 0.2s ease;
  
      &:hover {
        background-color: $button-color; // Darker blue on hover
        color: $font-color;
      }

      &:active {
        transform: scale(0.95); // Shrink on click
      }
    }
  
    .messagesContainer {
      flex: 1; // Take up available space
      padding: 10px;
      overflow-y: auto; // Add vertical scrolling for overflow
      display: flex;
      flex-direction: column; // Stack messages vertically
      gap: 10px; // Add spacing between messages
      overflow-y: auto; // Only scroll vertically for overflow
      scroll-behavior: smooth; // Smooth scrolling for modern browsers
      -webkit-overflow-scrolling: touch; // Enable smooth touch scrolling for mobile
  
      .message {
        display: flex;
        align-items: flex-start; // Align avatars and text
        gap: 10px; // Space between avatar and message
  
        &.bot {
          align-self: flex-start; // Align bot message to the left
        }
  
        &.user {
          // align-self: flex-end; // Align user message to the right
          flex-direction: row-reverse; // Place message before avatar
        }
  
        .img {
            color: $font-color;
            background-color: $layout-color;
        }
  
        .messageText {
           // message background
          color: $font-color; // Bot text color
          padding: 10px;
          border-radius: 12px; // Bubble-like messages
          
          word-wrap: break-word; // Handle long words
          font-size: 14px;
          text-align: left;
  
          &.user {
            background-color: $layout-color;
            color: $font-color;
            max-width: 70%; // Limit bubble width
          }
          &.bot {
            margin-right: auto; // Align bot messages to the left
          }
        }
      }
  
      // Typing indicator
      .typingIndicator {
        display: flex;
        gap: 3px;
  
        .dot {
          width: 8px;
          height: 8px;
          background-color: #ccc; // Gray dots
          border-radius: 50%; // Circular
          animation: bounce 1.5s infinite ease-in-out;
  
          // Delay for bouncing animations
          &:nth-child(1) {
            animation-delay: -0.3s;
          }
  
          &:nth-child(2) {
            animation-delay: -0.15s;
          }
        }
  
        @keyframes bounce {
          0%, 80%, 100% {
            transform: scale(0);
          }
          40% {
            transform: scale(1);
          }
        }
      }
    }
  
    .inputContainer {
      display: flex;
      padding: 10px;
      gap: 10px; // Spacing between input and button
      border-top: 1px solid $fade-color; // Border for separation
  
      .chatInput {
        flex: 1; // Input field takes remaining space
        height: 40px; // Fixed height
        padding: 5px 10px;
        border: 1px solid $fade-color; // Subtle border
        border-radius: 5px;
        outline: none; // Remove focus outline
        font-size: 14px;
        color: $font-color;
  
        &:focus {
          border-color: $button-color; // Blue border on focus
          box-shadow: 0 0 4px $button-color; // Focus glow
        }
      }
  
      .sendBtn {
        background-color: $button-color; // Blue button
        color: $font-color; // White text for button
        border: none; // Remove button border
        padding: 0 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: $button-color 0.3s ease, transform 0.2s ease;
  
        &:hover {
          background-color: $button-color; // Darker blue on hover
          color: $font-color;
        }
  
        &:active {
          transform: scale(0.95); // Shrink on click
        }
        .img{
            color: white;
        }
      }
    }
  
    .chatFooter {
      padding: 5px 10px;
      padding-right: 10px;
      font-size: 12px; // Small footer text
      color: $font-color; // Dimmed text for the footer
      text-align: center; // Center-align footer text
    }
  }